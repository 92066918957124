import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SocketContext from "./SocketContect";

export default function LivePl(props) {
  const item = props.item;

  const dispatch = useDispatch();

  const socket = useContext(SocketContext);

  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [pf, setpf] = useState(0);
  const [btotal, setBtotal] = useState(0);

  const sockettrade = (msg) => {
    if (msg != null) {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
        if (item.buy_sell == 0) {
          let Profitloss = 0;
          let brokerage_total = 0;
          if (msg.Bid > 0) {
            Profitloss =
              parseFloat(parseFloat(msg.Bid) - parseFloat(item.rate)) *
              parseFloat(item.qty);
            if (item.type == "fut") {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else if (item.type == "mcx") {
              if (item.brokerage < 10) {
                brokerage_total = parseFloat(
                  (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
                );
                brokerage_total = brokerage_total + item.brokerage_amt;
              } else {
                brokerage_total = item.brokerage_amt * 2;
              }
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            Profitloss = 0;
          }
          setpf(Profitloss - brokerage_total);
          setBtotal(brokerage_total);
        }
      } else {
        setbid(msg.LTP);
        if (item.buy_sell == 0) {
          let Profitloss = 0;
          let brokerage_total = 0;

          if (msg.LTP > 0) {
            Profitloss =
              parseFloat(parseFloat(msg.LTP) - parseFloat(item.rate)) *
              parseFloat(item.qty);

            if (item.type == "fut") {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else if (item.type == "mcx") {
              if (item.brokerage < 10) {
                brokerage_total = parseFloat(
                  (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
                );
                brokerage_total = brokerage_total + item.brokerage_amt;
              } else {
                brokerage_total = item.brokerage_amt * 2;
              }
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            Profitloss = 0;
          }

          setpf(Profitloss - brokerage_total);
          setBtotal(brokerage_total);
        }
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
        if (item.buy_sell == 1) {
          let Profitloss2 = 0;
          let brokerage_total = 0;
          if (parseFloat(msg.Ask) > 0) {
            Profitloss2 = (item.rate - msg.Ask) * item.qty;
            if (item.type == "fut") {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else if (item.type == "mcx") {
              if (item.brokerage < 10) {
                brokerage_total = parseFloat(
                  (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
                );
                brokerage_total = brokerage_total + item.brokerage_amt;
              } else {
                brokerage_total = item.brokerage_amt * 2;
              }
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            Profitloss2 = 0;
          }

          setpf(Profitloss2 - brokerage_total);
          setBtotal(brokerage_total);
        }
      } else {
        setask(msg.LTP);
        if (item.buy_sell == 1) {
          let Profitloss2 = 0;
          let brokerage_total = 0;
          if (parseFloat(msg.LTP) > 0) {
            Profitloss2 = (item.rate - msg.LTP) * item.qty;

            if (item.type == "fut") {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else if (item.type == "mcx") {
              if (item.brokerage < 10) {
                brokerage_total = parseFloat(
                  (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
                );
                brokerage_total = brokerage_total + item.brokerage_amt;
              } else {
                brokerage_total = item.brokerage_amt * 2;
              }
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            Profitloss2 = 0;
          }

          setpf(Profitloss2 - brokerage_total);
          setBtotal(brokerage_total);
        }
      }
    }
  };
  const socketbidask = (msg) => {
    if (msg != null) {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
        if (item.buy_sell == 0) {
          let Profitloss3 = 0;
          let brokerage_total = 0;
          Profitloss3 = (msg.Bid - item.rate) * item.qty;
          if (item.type == "fut") {
            brokerage_total = parseFloat(
              (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
            );
            brokerage_total = brokerage_total + item.brokerage_amt;
          } else if (item.type == "mcx") {
            if (item.brokerage < 10) {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            brokerage_total = item.brokerage_amt * 2;
          }
          setpf(Profitloss3 - brokerage_total);
          setBtotal(brokerage_total);
        }
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
        if (item.buy_sell == 1) {
          let Profitloss4 = 0;
          let brokerage_total = 0;
          Profitloss4 = (item.rate - msg.Ask) * item.qty;
          if (item.type == "fut") {
            brokerage_total = parseFloat(
              (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
            );
            brokerage_total = brokerage_total + item.brokerage_amt;
          } else if (item.type == "mcx") {
            if (item.brokerage < 10) {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            brokerage_total = item.brokerage_amt * 2;
          }
          setpf(Profitloss4 - brokerage_total);
          setBtotal(brokerage_total);
        }
      }
    }
  };

  useEffect(() => {
    socket.emit("giverate", item.symbol_id);

    socket.on("trade" + item.symbol_id, sockettrade);

    socket.on("bidask" + item.symbol_id, socketbidask);

    return () => {
      socket.off("trade" + item.symbol_id, sockettrade);

      socket.off("bidask" + item.symbol_id, socketbidask);
    };
  }, []);

  dispatch({
    type: "PF",
    id: item.id,
    pf: pf,
    btotal: btotal,
    total: props.total,
  });

  return <div key={item.id} />;
}
