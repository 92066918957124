import React, { useEffect, useState } from "react";
import Marquees from "react-fast-marquee";
import { Dark } from "../../../Theme/Theme";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import { Dialog } from "primereact/dialog";
import UpdateMarquee from "./UpdateMarquee/UpdateMarquee";
const backend = new Backend();

export default function Marquee() {
  const [show, setShow] = useState(false);
  const [marquees, setMarquee] = useState("");
  const [permission, setPermission] = useState("");

  useEffect(() => {
    load_marquee();
    // loadPermission();
  }, []);

  const load_marquee = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"), 
    };

    backend.load_marquee(data).then((r) => {
      if (r.error == "False") {
        setMarquee(r.marquee.text);
      } else {
        if (r.logout == 1) {
          localStorage.removeItem("token");
          localStorage.removeItem("id");
          localStorage.removeItem("username");
          localStorage.removeItem("server");
          localStorage.removeItem("AuthState");
          window.location = "/";
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      }
    });
  };

  // const loadPermission = () => {
  //   let data = {
  //     token: localStorage.getItem("token"),
  //     id: localStorage.getItem("id"),
  //     username: localStorage.getItem("username"),
  //     server: localStorage.getItem("server"), 
  //   };

  //   backend.permission(data).then((r) => {
  //     if (r.error == "False") {
  //       setPermission(r.permission);
  //     } else {
  //       toast.error(r.message, {
  //         backgroundColor: Dark.sell,
  //         color: Dark.text,
  //       });
  //     }
  //   });
  // };

  return (
    <div
      onClick={() => setShow(true)}
      // onClick={() =>
      //   permission == "" ? null : permission.marquee == 1 ? setShow(true) : null
      // }
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "45px",
      }}
    >
      <Marquees style={{ fontSize: 22, color: Dark.text }}>
        {marquees == undefined ? "NO HEADLINE FOUND" : marquees}
      </Marquees>

      <Dialog
        visible={show}
        showHeader={false}
        className="modalNew"
        style={{ width: "40vw", height: "32vh" }}
        onHide={() => setShow(false)}
      >
        <UpdateMarquee load={load_marquee} close={() => setShow(false)} />
      </Dialog>
    </div>
  );
}
