import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { InputText } from "primereact/inputtext";

import Backend from "../../../../Backend/Backend";
import ReactLoading from "react-loading";
import "./AddClient.css";
import { toast } from "react-toast";
import { Dialog } from "primereact/dialog";
import TPassword from "../../../../Healer/TPassword/TPassword";

const backend = new Backend();
export default function AddClient(props) {
  const [loading, setLoading] = useState("");
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const [show, setShow] = useState(false);
  const [tpassword, setTPassword] = useState("");

  const add_client = () => {
    setShow(false);

    if (username == "") {
      toast.error("Invalid Client Username", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (name == "") {
      toast.error("Invalid Client Name", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (password == "") {
      toast.error("Invalid Client Password", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else {
      setLoading(true);

      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        username: localStorage.getItem("username"),
        server: localStorage.getItem("server"),
        client_username: username,
        client_name: name,
        client_password: password,
        tpassword: tpassword,
      };

      backend.add_client(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          props.reload();
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    }
  };

  return (
    <div
      style={{
        height: "78vh",
        backgroundColor: Dark.background,
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          color: Dark.text,
          height: "55px",
          backgroundColor: Dark.primary,
          width: "100%",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          display: "flex",
          alignItems: "center",
          textTransform: "uppercase",
          paddingLeft: 20,
        }}
      >
        <div style={{ flex: 1 }}>Add Client</div>

        <div
          onClick={() => (loading ? null : setShow(true))}
          style={{
            height: 45,
            width: 100,
            backgroundColor: Dark.buy,
            marginRight: 50,
            borderRadius: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <ReactLoading
              type={"spin"}
              color={Dark.text}
              height={25}
              width={25}
            />
          ) : (
            "SUBMIT"
          )}
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          height: "105px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          textTransform: "uppercase",
          paddingLeft: 20,
        }}
      >
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <div style={{ paddingRight: 10, width: 150 }}>USERNAME</div>
          <div className="loginInputAddAdmin">
            <InputText
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              placeholder="Enter Username"
            />
          </div>
        </div>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <div style={{ paddingRight: 10, width: 150 }}>NAME</div>
          <div className="loginInputAddAdmin">
            <InputText
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Enter Name"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          height: "55px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          textTransform: "uppercase",
          paddingLeft: 20,
        }}
      >
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <div style={{ paddingRight: 10, width: 150 }}>PASSWORD</div>
          <div className="loginInputAddAdmin">
            <InputText
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Enter Password"
            />
          </div>
        </div>
      </div>

      <Dialog
        visible={show}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setShow(false)}
      >
        <TPassword Update={add_client} tpassword={(a) => setTPassword(a)} />
      </Dialog>
    </div>
  );
}
