import React from "react";
import { Dark } from "../../../../Theme/Theme";
import { toast } from "react-toast";
export default function BrokerCard(props) {
  const i = props.item;

  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(`
      🙏 WELCOME TO 🙏 
      ARROWTRADE
  😎 BROKER 》》
        
  🌐 SERVER 》》 ${i.server}
  🚹 USER NAME 》》 ${i.broker_name}
  🚹 TPASSWORD 》》 ${i.tpassword_broker}
  🔐 PASSWORD 》》 ${"RAM007"}
  🖥 FOR PC / IOS  : 
  
  Ios : https://broker.arrowtrad.com;
  Web/Desktop : https://broker.arrowtrad.com
  
  CONTACT YOUR UPLINE
 IF YOU FACE ANY PROBLEM IN TRADING
      `);

      toast.success("Copied", {
        backgroundColor: Dark.buy,
        color: Dark.text,
      });
    } catch (err) {
      toast.error("Failed to copy!", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    }
  };

  return (
    <div
      onDoubleClick={copyToClipBoard}
      onClick={() => props.setBroker(i)}
      style={{
        backgroundColor: Dark.secondary,
        color: Dark.text,
        padding: 5,
        height: 90,
        marginBottom: 15,
        borderRadius: 10,
        display: "flex",
        borderStyle: "solid",
        borderColor: props.broker.id == i.id ? Dark.primary : Dark.secondary,
      }}
    >
      <div
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <div style={{ paddingBottom: 5, fontSize: 15 }}>
          Server - {i.server}
        </div>
        <div style={{ paddingBottom: 5, fontSize: 15 }}>
          Username - {i.broker_name}
        </div>
        <div style={{ paddingBottom: 5, fontSize: 15 }}>
          TPassword - {i.tpassword_broker}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Status :{" "}
          <div style={{ color: i.status == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.status == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>

      </div>
    </div>
  );
}
