import React, { useState } from "react";
import { Dark } from "../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import "./Password.css";
import Loading from "react-loading";
export default function TPassword(props) {
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState("");

  const sumbmit = () => {
    if (loading) {
    } else {
      props.Update();
    }

    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: 45,
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        ESC TO CLOSE
      </div>

      <div
        style={{
          height: 80,
          margin: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loginInputTpass">
          <InputText
            autoComplete="off"
            type="password"
            autocomplete="new-password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              props.tpassword(e.target.value);
            }}
            autoFocus={true}
            placeholder="Enter Transaction Password"
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                if (loading) {
                } else {
                  props.Update();
                }
              }
            }}
          />
        </div>
      </div>
      <div
        onClick={loading ? null : sumbmit}
        style={{
          height: 45,
          backgroundColor: Dark.primary,
          margin: 10,
          width: "80%",
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        {loading ? <Loading type="spin" height={20} width={20} /> : "SUBMIT"}
      </div>
    </div>
  );
}
