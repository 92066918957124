import React, { useState } from "react";
import ClientList from "./ClientList/ClientList";
import Clients from "./Client/Client";
import AddClient from "./AddClient/AddClient";
import { Dark } from "../../../Theme/Theme";

import Summary from "./Summary/Summary";
export default function Client() {
  const height = window.innerHeight;
  const [type, setType] = useState(0);
  const [client, setClient] = useState("");
  const [update, setupdate] = useState(0);

  const AddNewClient = () => {
    setType(1);
  };
  const ShowSummary = () => {
    setType(0);
  };

  return (
    <div
      style={{
        height: "100%",
        height: height / 1.24,
        display: "flex",
      }}
    >
      <div style={{ flex: 3, margin: 10 }}>
        {type == 0 ? <Summary /> : null}
        {type == 2 ? (
          <ClientList
            client={client}
            reload={() => {
              setupdate(1);
            }}
          />
        ) : null}
        {type == 1 ? (
          <AddClient
            reload={() => {
              setupdate(1);
              ShowSummary();
            }}
          />
        ) : null}
      </div>
      <div style={{ flex: 1, backgroundColor: Dark.background }}>
        <Clients
          Add={AddNewClient}
          ShowSummary={ShowSummary}
          setClient={(a) => {
            setClient(a);
            setType(2);
          }}
          key={update}
          client={client}
        />
      </div>
    </div>
  );
}
